@tailwind base;
@tailwind components;
@tailwind utilities;

/* Define CSS variables for dynamic styling */
:root {
  --scrollbar-color: #2888b5;
  --scrollbar-track-color: #DFE9EB;
}

/* Firefox (uncomment to work in Firefox, although other properties will not work!)  */
/* * {
  scrollbar-width: thin;
  scrollbar-color: #2888B5 #DFE9EB;
} */

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}
*::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: var(--scrollbar-track-color);
  border: 1px inset #FFFFFF;
}
*::-webkit-scrollbar-track:hover {
  background-color: #B8C0C2;
}
*::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: var(--scrollbar-color);
  border: 3px solid var(--scrollbar-color);
}
*::-webkit-scrollbar-thumb:hover {
  background-color: var(--scrollbar-color);
}

*::-webkit-scrollbar-thumb:active {
  background-color: var(--scrollbar-color);
}
/* .custom-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
} */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: transparent;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
